
import { computed, defineComponent, ref, watch } from 'vue'
import BaseModal from '../base/BaseModal.vue'
import BaseInput from '../base/BaseInput.vue'
import { useToggle } from '@/composables/useToggle'

export default defineComponent({
  components: { BaseModal, BaseInput },

  props: {
    form: {
      type: Object,
      required: true
    },
    currentErrors: {
      type: Object,
      default: () => {}
    }
  },

  emits: [
    'update:form'
  ],

  setup (props, { emit }) {
    const profile = ref<any>({
      competitors: props.form.competitors,
    })

    const {
      isActive: isModalActive,
      setInactive: closeModal
    } = useToggle(false)

    
    const competitorsMax = 3

    const formBuffer = ref(JSON.parse(JSON.stringify(profile.value)))

    const notEmptyCompetitors = computed(() => {
      const competitors: string[] = profile.value.competitors.filter((competitor: string) => !!competitor.trim())
      return [...new Set(competitors)]
    })

    const isValidCompetitors = computed(() => {
      const competitors = notEmptyCompetitors.value
      const regex: string = "(@)?(href=')?(HREF=')?(HREF=\")?(href=\")?(http://)?[a-zA-Z_0-9\\-]+(\\.\\w[a-zA-Z_0-9\\-]+)+(/[#&\\n\\-=?\\+\\%/\\.\\w]+)?"
      const invalidCompetitors = competitors.filter(competitor => !competitor.match(regex))
      return invalidCompetitors.length === 0
    })

    const resetForm = () => {
      profile.value = JSON.parse(JSON.stringify(formBuffer.value))
      closeModal()
    }

    const saveForm = async () => {
      profile.value = { competitors: notEmptyCompetitors.value }

      emit('update:form', profile.value)
      
      formBuffer.value = JSON.parse(JSON.stringify(profile.value))

      closeModal()
    }

    watch(isModalActive, (newValue) => {
      if (newValue === false) {
        resetForm()
      }
    })

    return {
      profile,
      competitorsMax,
      saveForm,
      resetForm, 
      isValidCompetitors,
      isModalActive,
      closeModal
    }
  }
})
