<template>
  <div class="card">
    <div class="card-header">
      <div>
        <div class="card-title">{{ $t('page.settings.competitors.title') }}</div>
        <small>{{ $t('page.settings.competitors.description') }}</small>
      </div>
    </div>
  
    <div class="card-body">
      <div
        class="flex flex-row flex-wrap justify-start items-center w-full gap-2"
      >
        <div
          v-for="(_competitor, index) in competitorsMax" :key="`competitor-${index}`"
          class="rounded w-10 min-w-max h-6 p-4 flex justify-center items-center text-gray-600 border border-gray-400"
        >
          {{ profile.competitors[index] }}
        </div>
      </div>
    </div>
    
    <div class="card-footer flex flex-row flex-wrap justify-end items-center">
      <base-modal v-model:open="isModalActive" hide-footer>
        <template v-slot:button>
          <slot name="button">
            <button
              type="button"
              class="btn btn-primary sm:text-sm"
              >
              {{ $t('action.edit') }}
            </button>
          </slot>
        </template>
    
        <div class="card-header">
          <div class="card-title">
            {{ $t('page.settings.competitors.title') }}
          </div>
        </div>
        <div class="card-body py-4 space-y-6 text-left">
          <base-input
            v-for="(competitor, index) in competitorsMax"
            :key="`competitor-${index}`"
            :label="$t('page.settings.competitors.form.competitor.label-dynamic', { order: index + 1 })"
            :name="`competitor-${index}`"
            type="url"
            v-model="profile.competitors[index]"
            autocomplete="off"
            :autofocus="index === 0"
            left-icon="fas fa-globe"
          />
    
          <div>
            <p class="text-sm text-gray-500">
              {{ $t('page.settings.competitors.sentence.can_be_empty') }}
            </p>
          </div>
        </div>
        <div
          class="card-footer sm:flex sm:flex-row-reverse space-y-3 sm:space-y-0 sm:space-x-reverse sm:space-x-3"
        >
          <button
            @click="saveForm"
            type="button"
            class="btn btn-primary w-full sm:w-auto sm:text-sm"
            :disabled="!isValidCompetitors"
          >
            {{ $t('action.save') }}
          </button>
          <button
            @click="resetForm"
            type="button"
            class="btn w-full sm:w-auto sm:text-sm"
          >
            {{ $t('action.cancel') }}
          </button>
        </div>
      </base-modal>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue'
import BaseModal from '../base/BaseModal.vue'
import BaseInput from '../base/BaseInput.vue'
import { useToggle } from '@/composables/useToggle'

export default defineComponent({
  components: { BaseModal, BaseInput },

  props: {
    form: {
      type: Object,
      required: true
    },
    currentErrors: {
      type: Object,
      default: () => {}
    }
  },

  emits: [
    'update:form'
  ],

  setup (props, { emit }) {
    const profile = ref<any>({
      competitors: props.form.competitors,
    })

    const {
      isActive: isModalActive,
      setInactive: closeModal
    } = useToggle(false)

    
    const competitorsMax = 3

    const formBuffer = ref(JSON.parse(JSON.stringify(profile.value)))

    const notEmptyCompetitors = computed(() => {
      const competitors: string[] = profile.value.competitors.filter((competitor: string) => !!competitor.trim())
      return [...new Set(competitors)]
    })

    const isValidCompetitors = computed(() => {
      const competitors = notEmptyCompetitors.value
      const regex: string = "(@)?(href=')?(HREF=')?(HREF=\")?(href=\")?(http://)?[a-zA-Z_0-9\\-]+(\\.\\w[a-zA-Z_0-9\\-]+)+(/[#&\\n\\-=?\\+\\%/\\.\\w]+)?"
      const invalidCompetitors = competitors.filter(competitor => !competitor.match(regex))
      return invalidCompetitors.length === 0
    })

    const resetForm = () => {
      profile.value = JSON.parse(JSON.stringify(formBuffer.value))
      closeModal()
    }

    const saveForm = async () => {
      profile.value = { competitors: notEmptyCompetitors.value }

      emit('update:form', profile.value)
      
      formBuffer.value = JSON.parse(JSON.stringify(profile.value))

      closeModal()
    }

    watch(isModalActive, (newValue) => {
      if (newValue === false) {
        resetForm()
      }
    })

    return {
      profile,
      competitorsMax,
      saveForm,
      resetForm, 
      isValidCompetitors,
      isModalActive,
      closeModal
    }
  }
})
</script>
